import Head from 'next/head';
import React from 'react';

import type { Route } from 'nextjs-routes';
import type { Props as PageProps } from 'nextjs/getServerSideProps';

import useAdblockDetect from 'lib/hooks/useAdblockDetect';
import useGetCsrfToken from 'lib/hooks/useGetCsrfToken';
import * as metadata from 'lib/metadata';
import * as mixpanel from 'lib/mixpanel';
import { init as initSentry } from 'lib/sentry/config';

interface Props<Pathname extends Route['pathname']> {
  pathname: Pathname;
  children: React.ReactNode;
  query?: PageProps<Pathname>['query'];
  apiData?: PageProps<Pathname>['apiData'];
}

initSentry();

const PageNextJs = <Pathname extends Route['pathname']>(props: Props<Pathname>) => {
  const { opengraph, canonical } = metadata.generate(props, props.apiData);

  useGetCsrfToken();
  useAdblockDetect();

  const isMixpanelInited = mixpanel.useInit();
  mixpanel.useLogPageView(isMixpanelInited);

  return (
    <>
      <Head>
        <title>BETHRINCE Explorer</title>
        <meta name="description" content="BETHRINCE Explorer available today, data availability, APIs, and ecosystem tools to support their networks."/>
        <meta httpEquiv="Content-Security-Policy" content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"></meta>
        { canonical && <link rel="canonical" href={ canonical }/> }

        { /* OG TAGS */ }
        <meta property="og:title" content="BETHRINCE Explorer"/>
        { opengraph.description && <meta property="og:description" content="BETHRINCE Explorer available today, data availability, APIs, and ecosystem tools to support their networks."/> }
        <meta property="og:image" content={ opengraph.imageUrl }/>
        <meta property="og:type" content="website"/>

        { /* Twitter Meta Tags */ }
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:domain" content="https://bethscan.org/"/>
        <meta name="twitter:title" content="BETHRINCE Explorer"/>
        { opengraph.description && <meta name="twitter:description" content="BETHRINCE Explorer available today, data availability, APIs, and ecosystem tools to support their networks."/> }
        <meta property="twitter:image" content={ opengraph.imageUrl }/>
      </Head>
      { props.children }
    </>
  );
};

export default React.memo(PageNextJs);
